import useDarkMode from "../hooks/useDarkMode";

export default function DarkModeToggle() {
  const [darkMode, setDarkMode] = useDarkMode();
  return (
    <div
      onClick={() => setDarkMode(!darkMode)}
      className="p-2 bg-gray-800 rounded-full shadow hover:bg-gray-600"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5 text-gray-100 dark:text-purple-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
      </svg>
    </div>
  );
}
