import { useEffect, useState } from "react";
import DarkModeToggle from "./components/DarkModeToggle";
import Logo from "./components/Logo";
import axios from "axios";

const IMG_URL =
  "https://recycle-cam-1617602233920.appspot.com.storage.googleapis.com/public/snapshot.jpg";

function getRelativeTime(d1: Date, d2 = new Date()) {
  // Copied from https://stackoverflow.com/a/53800501/913153
  const units: { [k: string]: number } = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const elapsed = d1.getTime() - d2.getTime();
  // "Math.abs" accounts for both "past" & "future" scenarios
  for (let u in units) {
    if (u === "second") return elapsed < 0 ? "< 1 minute ago" : "in < 1 minute";
    if (Math.abs(elapsed) > units[u])
      return rtf.format(
        Math.round(elapsed / units[u]),
        u as Intl.RelativeTimeFormatUnit
      );
  }
}

function getLastRefresh(d: Date) {
  const date = new Date(d.getTime());
  date.setMilliseconds(0);
  date.setSeconds(date.getSeconds() - (date.getSeconds() % 30)); // 30-second intervals
  return String(date.getTime());
}

function App() {
  const [now, setNow] = useState(new Date());
  const [refreshInterval, setRefreshInterval] = useState(getLastRefresh(now));
  const [lastModified, setLastModifiedDate] = useState<Date | null>(null);

  function refreshData() {
    const newTime = new Date();
    setNow(newTime);
    const newLastImgRefresh = getLastRefresh(newTime);
    setRefreshInterval(newLastImgRefresh);
    const newImgUrl = `${IMG_URL}?${newLastImgRefresh}`;
    const fetchImgData = async () => {
      await axios(newImgUrl)
        .then((res) => {
          const lastModified = new Date(res.headers["last-modified"]);
          setLastModifiedDate(lastModified);
        })
        .catch((e) => {
          console.log(e);
          setLastModifiedDate(null);
        });
    };
    fetchImgData();
  }

  useEffect(() => {
    refreshData();
    const interval = setInterval(refreshData, 30000);
    return () => clearInterval(interval);
  }, []);

  let dateStr = "";
  let lastUpdateStr;
  let nextUpdateTime;
  let showNextUpdate;
  let nextUpdateStr;
  if (lastModified) {
    dateStr = lastModified.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
    lastUpdateStr = getRelativeTime(lastModified, now);
    nextUpdateTime = new Date(lastModified.getTime() + 15 * 60 * 1000 + 30000); // 15m30s
    showNextUpdate = now.getTime() - lastModified.getTime() < 17 * 60 * 1000; // Show if last update was < 17m ago
    if (showNextUpdate && nextUpdateTime.getTime() < now.getTime()) {
      // The next update is late, but time elapsed is still < 20 min since last update
      nextUpdateStr = "in < 1 minute"; // Assume that next update is imminent
    } else {
      nextUpdateStr = getRelativeTime(nextUpdateTime, now);
    }
  }
  const photo = (
    <>
      <div>
        <img
          alt={dateStr}
          src={`${IMG_URL}?${refreshInterval}`}
          className="w-96"
        />
      </div>
      <div className="px-6 py-4 sm:text-center">
        <div className="mb-2 text-2xl font-bold text-gray-900 dark:text-gray-200">
          Recycle Cam
        </div>
        {lastModified && (
          <>
            <p className="text-base text-gray-600 dark:text-gray-400">
              Last updated {lastUpdateStr}
            </p>
            {showNextUpdate && (
              <p className="text-base text-gray-600 dark:text-gray-400">
                Next update {nextUpdateStr}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );

  return (
    <div className="flex flex-col justify-start h-full mx-auto max-w-7xl">
      <div className="w-full sm:overflow-hidden sm:pb-4">
        <div className="flex items-center justify-between px-4 py-2 sm:shadow">
          <Logo />
          <DarkModeToggle />
        </div>
      </div>
      <div className="flex flex-col justify-center h-full">
        <div className="mx-auto">
          <div className="absolute hidden sm:block">
            <div className="hidden px-4 pt-4 border border-gray-500 shadow-2xl dark:border-gray-800 sm:block bg-gray-50 dark:bg-gray-800 transform -rotate-12">
              {photo}
            </div>
          </div>
          <div className="relative">
            <div className="m-4 overflow-hidden border-gray-500 shadow-2xl dark:border-gray-800 dark:bg-gray-800 sm:border rounded-xl sm:px-4 sm:pt-4 bg-gray-50 sm:rounded-none">
              {photo}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
